<template>
  <master-detail
    formTitle="Cadastro de Consumo de Materiais"
    descriptionProperty="descricao"
    :actionBarButtons="actionBarButtons"
    :canDelete="false"
    :canEdit="true"
    :cols="cols"
    :formWidth="700"
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :hasNewButton="false"
    :hasMonthlyFilter="true"
    :opts="opts"
    :resourceUrl="resourceUrl"
    :serverPagination="true"
    :selectionCol=true
    ref="masterDetail"
    :selection.sync="selection"
    @update:monthlyFilter="getProjects($event)"
    @update:selection="selection = $event"
  >
    <v-menu offset-y dense>
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          class="table-v-action-button mr-2"
        >
          <v-icon>mdi-file-export-outline</v-icon>Exportação
        </div>
      </template>
      <v-list>
        <v-list-item
          style="cursor: pointer"
          @click="handleExport('csv')"
        >
          <v-list-item-title class="v-label-input">
            <v-icon left>mdi-file-delimited-outline</v-icon>Exportar CSV
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          style="cursor: pointer"
          @click="handleExport('xlsx')"
        >
          <v-list-item-title class="v-label-input">
            <v-icon left>mdi-file-excel-outline</v-icon>Exportar XLSX
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <FormModal title="Edição de materiais"
      :opened.sync="isVisibleModalUpdate"
      :value.sync="modalUpdateData"
      :cols="updateCols"
      :opts="opts"
      @save="updateMateriais()">
    </FormModal>
  </master-detail>
</template>

<script>
import { mapGetters } from 'vuex';
import { TipoImportacaoEnum } from '@/core/enums/importacoes';
import { UserTypeEnum } from '@/core/enums/user-types';

export default {
  components: {
    "master-detail": () => import("@/components/master-detail.vue"),
    "FormModal": () => import("@/components/form-modal.vue"),
  },
  computed: {
    ...mapGetters(['clientId', 'getUserType', 'intervaloCompetencia']),
    isClient: function () {
      return this.getClient().isClient;
    },
    isConsultor: function () {
      return ![UserTypeEnum.COLABORADOR, UserTypeEnum.GERENCIAL_CLIENTE].includes(this.getUserType);
    },
    resourceUrl: function () {
      return `/v1/fiscal/nfoperacional/${this.clientId}`;
    },
    projetosEnquadradosResource: function () {
      return this.apiResource(`/v1/projetos/${this.clientId}/selecao`);
    },
    unidadesResource: function () {
      return this.apiResource(`/v1/clientes/${this.clientId}/unidades`);
    },
    cols: function () {
      return [
        {
          key: "op",
          name: "OP",
          editable: false,
          type: this.$fieldTypes.TEXT,
          colSize: 6,
          filterable: true,
        },
        {
          key: "cod_produto",
          name: "Cód. Prod.",
          editable: false,
          type: this.$fieldTypes.TEXT,
          colSize: 6,
        },
        {
          key: "descricao",
          name: "Descrição",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "qtd_movimento",
          name: "Qtd. Moviment.",
          type: this.$fieldTypes.DOUBLE,
          colSize: 4,
          align: 1,
        },
        {
          key: "vlr_unitario",
          name: "Vlr Unit.",
          type: this.$fieldTypes.MONEY,
          colSize: 4,
          align: 1,
        },
        {
          key: "vlr_tot_movimento",
          name: "Vlr Total",
          type: this.$fieldTypes.MONEY,
          colSize: 4,
          align: 1,
        },
        {
          key: "data_movimento",
          name: "Dt Movimento",
          type: this.$fieldTypes.DATE,
          colSize: 6,
        },
        {
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
          colSize: 6,
        },
        {
          key: "projetoId",
          name: "Projeto",
          align: -1,
          nowrap: true,
          type: this.$fieldTypes.AUTOCOMPLETE,
          rules: [{ rule: "required" }],
          rel: { to: "projetos", key: "id", name: "titulo" },
        },
        {
          key: "origem",
          name: "Origem",
          rel: { to: "origem", key: "id", name: "nome" },
          type: this.$fieldTypes.SELECT,
          colSize: 6,
          width: '190px',
          filterable: true,
        },

        {
          key: "classificacao",
          name: "Classificação",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
          hideInTable: !this.isConsultor,
          hideInform: true,
        },
        {
          key: "unidadeId",
          name: "Unidade",
          align: -1,
          colSize: 6,
          type: this.$fieldTypes.AUTOCOMPLETE,
          rules: [{ rule: "required" }],
          rel: { to: "unidades", key: "id", name: "unidade" }
        },
        {
          key: "jobId",
          name: "ID Importação",
          type: this.$fieldTypes.TEXT,
          hideInform: true,
          filterable: true,
        },
      ];
    },
    actionBarButtons: function () {
      const options = [
        {
          text: 'Atualizar materiais',
          icon: 'mdi-square-edit-outline',
          show: this.selection.length > 0,
          action: () => {
            this.isVisibleModalUpdate = true;
          }
        },
        {
          text: "Importar Baixa de Estoque (Planilha)",
          icon: "mdi-file-import-outline",
          show: this.isClient ? this.userHasAccess("Importacoes.dispendios.planilhaDeConsumoDeMateriais") : this.userHasAccess("Importacoes.resource.index"),
          action: () => {
            const route = this.isClient ? { name: "listagem-importacoes-consumo-materiais" } : { name: "listagem-importacoes", query: { tipo: TipoImportacaoEnum.FISCAL_NF_CONSUMO_MATERIAIS } };
            this.$router.push(route);
          },
        }
      ]
      return options.filter((item) => item.show);
    },
    updateCols: function() {
      return [
      {
          key: "projetoId",
          name: "Projeto",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "projetos", key: "id", name: "titulo" },
        },
        {
          key: "unidadeId",
          name: "Unidade",
          type: this.$fieldTypes.AUTOCOMPLETE,
          colSize: 6,
          rel: { to: "unidades", key: "id", name: "unidade" },
        },
        {
          key: "origem",
          name: "Origem",
          rel: { to: "origem", key: "id", name: "nome" },
          colSize: 6,
          type: this.$fieldTypes.SELECT,
        }
      ]
    }
  },
  created: function () {
    this.getUnidades();
  },
  data: function () {
    return {
      lastYear: null,
      opts: {
        projetos: [],
        origem: [
          { id: 0, nome: "Importado" },
          { id: 1, nome: "Nacional" },
          { id: -1, nome: "Nenhum" },
        ],
        unidades: []
      },
      selection: [],
      isVisibleModalUpdate: false,
      modalUpdateData: {},
    };
  },
  methods: {
    getProjects: function (competencia) {
      const ano = (competencia && competencia[0])?.substring(0, 4) || this.lastYear;

      if (ano && (!competencia || ano !== this.lastYear)) {
        this.lastYear = ano;
        const query = `ano=${ano}`;
        this.projetosEnquadradosResource.get({ query }).then((response) => {
          this.opts.projetos = response.projetos;
          this.opts.projetos.unshift({ id: 0, titulo: 'Nenhum' });
        });
      }
    },
    getUnidades: function () {
      this.unidadesResource.get().then((response) => {
        this.opts.unidades = Array.isArray(response) ? response : [];
        this.opts.unidades.unshift({ id: 0, unidade: 'Nenhuma' });
      });
    },
    handleExport: function (type) {
      const [competenciaIni, competenciaFim] = this.intervaloCompetencia;
      this.apiResource(`${this.resourceUrl}?competenciaIni=${competenciaIni}&competenciaFim=${competenciaFim}`)
        .get()
        .then((rows) => {
          const parsed = [this.cols.map(({ name }) => name)]
            .concat(
              rows
                .map((row) => this.cols.map(({ key, rel, type }) => {
                  const value = row[key];
                  switch (type) {
                    case this.$fieldTypes.DATE:
                      return this.$options.filters.toDate(value);
                    case this.$fieldTypes.MONTH:
                      return this.$options.filters.toMonth(value);
                    case this.$fieldTypes.MONEY:
                      return this.$options.filters.toCurrency(value);
                    case this.$fieldTypes.AUTOCOMPLETE:
                    case this.$fieldTypes.SELECT:
                      // eslint-disable-next-line no-case-declarations
                      const option = this.opts[rel.to].find((opt) => opt[rel.key] == value);
                      return option ? option[rel.name] : value;
                    default:
                      return value;
                  }
                }))
            );

          if (type === 'csv') {
            this.exportCsv(parsed);
          } else {
            this.exportXlsx(parsed);
          }
        });
    },
    updateMateriais: function() {
      const itemIds = this.selection.map((item) => (item.id));
      const saveData = { ...this.modalUpdateData, itemIds: itemIds, competencia: this.intervaloCompetencia}
      const resource = this.apiResource(`v1/fiscal/nfoperacional/${this.clientId}/update`);
      return resource.save(saveData).then(() => {
        this.isVisibleModalUpdate = false;
        this.modalUpdateData = {};
        this.selection = [];
        this.modalUpdateData = {};
        this.$refs.masterDetail.doLoad();
      })
    },
  },
  watch: {
    clientId: function () {
      this.getUnidades();
      this.getProjects();
    },
  },
};
</script>
